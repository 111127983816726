import {
  getUrlWithProtocol,
  IconApartment,
  IconBoard,
  IconUser,
} from '@bbl-digital/snorre'
import { useTheme } from '@emotion/react'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  isForkjopActive,
  isMinSideActive,
  isPortalActive,
  getBblSettings,
} from 'shared/store/bbl/selectors'
import {
  getCustomtextForkjop,
  getCustomtextMinside,
  getCustomtextPortal,
} from 'shared/store/customtexts/selectors'
import HomeHeader from './Header'
import ProductCard from './ProductCard'
import { CardsWrapper, Wrapper } from './styles'

const Home: React.FC = () => {
  const theme: any = useTheme()

  const preemptionBblCode =
    window.config.bblCode === 'sbbl'
      ? 'sobo'
      : window.config.bblCode === 'moborana'
      ? 'helgelandbbl'
      : window.config.bblCode === 'pbbl'
      ? 'gbbl'
      : window.config.bblCode

  const customtextForkjop = useSelector(getCustomtextForkjop)
  const customtextPortal = useSelector(getCustomtextPortal)
  const customtextMinside = useSelector(getCustomtextMinside)

  const hasPortal = useSelector(isPortalActive)
  const hasMinSide = useSelector(isMinSideActive)
  const hasForkjop = useSelector(isForkjopActive)

  const bblSettings = useSelector(getBblSettings)

  return (
    <Wrapper>
      <HomeHeader />

      <CardsWrapper>
        {hasForkjop && (
          <ProductCard
            headerIcon={
              <IconApartment size="32px" color={theme.secondaryDarkText} />
            }
            headerText="Forkjøp"
            text={customtextForkjop}
            url={window.config.urls.forkjopUrl + preemptionBblCode}
          />
        )}
        {hasMinSide && (
          <ProductCard
            headerIcon={
              <IconUser size="32px" color={theme.secondaryDarkText} />
            }
            headerText="Min side"
            text={customtextMinside}
            url={window.config.urls.minSideUrl}
          />
        )}
        {hasPortal && (
          <ProductCard
            headerIcon={
              <IconBoard size="32px" color={theme.secondaryDarkText} />
            }
            headerText="Portal"
            text={customtextPortal}
            url={getUrlWithProtocol(
              bblSettings?.urls.newPortal ||
                window.config.bblCode.toLowerCase() +
                  window.config.urls.portalUrl
            )}
          />
        )}
      </CardsWrapper>
    </Wrapper>
  )
}

export default Home
